import { useHistory } from 'react-router-dom';
import styles from './styles.module.scss';

const About = () => {
  const history = useHistory();

  return (
    <div className={styles.about}>
      <div className={styles.aboutMain}>
        <div className={styles.aboutMainHeader}>
          <div>
            <img src='/images/home/logo_white.png' alt='logo' />
          </div>
          <div>
            <span
              className={styles.buttonHome}
              onClick={() => history.push('/')}
            >
              首页
            </span>
            <span
              className={styles.buttonAbout}
              onClick={() => history.push('/about')}
            >
              关于我们
            </span>
          </div>
        </div>
        <div className={styles.aboutMainContent}>
          <div className={styles.aboutMainContentLeft}>
            <div className={styles.aboutMainContentLeftTitle}>
              我们正在重塑云计算安全
            </div>
            <div className={styles.aboutMainContentLeftContent}>
              <ul>
                <li>
                  采用创新的无代理安全、可视化图分析以及基于AI的风险预测等技术，
                  帮助用户快速识别并解决潜在的安全风险，建立安全的云计算环境，
                  加速其业务的创新及发展。
                </li>
                <li>
                  主要成员为连续成功创业者，在云计算安全领域深耕多年，
                  曾制定多项云计算安全标准。
                </li>
              </ul>
              <div></div>
            </div>
          </div>
          <div className={styles.aboutMainContentRight}>
            <div>
              <img
                className={styles.imgLogo}
                src='/images/about/logo.png'
                alt='logo'
              />
              <div>
                联系邮箱
                <br />
                contact@sysentry.com
              </div>
            </div>
            <div>
              <img
                className={styles.imgQR}
                src='/images/about/QRCode.png'
                alt='QRCode'
              />
              <div>
                扫码立即咨询
                <br />
                哨云安全专家
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
