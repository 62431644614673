import { Form, Input, notification } from 'antd';
import cName from 'classnames';
import { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { IPopupRef, Popup } from '../../components/popup';
import styles from './styles.module.scss';

const Index = () => {
  const history = useHistory();
  const popupRef = useRef<IPopupRef>(null);

  const [form] = Form.useForm();

  const onOk = () => {
    form.submit();
  };

  const onFinish = (values: any) => {
    console.log('Received values of form: ', values);
    popupRef.current?.close();
    notification.success({
      message: '预约成功，请耐心等待。',
    });
  };

  return (
    <div className={styles.home}>
      <div className={styles.pageA}>
        <div className={styles.wrap}>
          <div className={styles.header}>
            <div>
              <img src='/images/home/logo_white.png' alt='logo' />
            </div>
            <div>
              <span
                className={styles.buttonHome}
                onClick={() => history.push('/')}
              >
                首页
              </span>
              <span
                className={styles.buttonAbout}
                onClick={() => history.push('/about')}
              >
                关于我们
              </span>
            </div>
          </div>
          <div className={styles.main}>
            <div className={styles.content}>
              <div className={styles.title}>多云环境的风险预测和提前处置</div>
              <div className={styles.desc}>
                采用统一的云安全管理平台，对云基础架构、操作系统、应用、身份、权限、
                数据等进行关联分析，结合常用攻击方法、漏洞利用行为的模型，
                识别并修补高优先度的潜在风险。
              </div>
              <div className={styles.action}>
                <a
                  href='https://sysentry.cloud'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <button className={styles.buttonVisitNow}>立即访问</button>
                </a>
                {/* <button
                  className={styles.buttonApplyForDemo}
                  onClick={(): void => {
                    popupRef.current?.open();
                  }}
                >
                  <span>申请演示</span>
                </button> */}
                <a href='mailto:contact@sysentry.com?subject=产品演示申请&&body=请填写申请信息,我们会在第一时间与您接洽%0D%0A企业名称%20%3A%0D%0A联系人%20%3A%0D%0A联系方式%20%3A%0D%0A'>
                  <button className={styles.buttonApplyForDemo}>
                    <span>申请演示</span>
                  </button>
                </a>
              </div>
            </div>
            <div className={styles.image}>
              <img src='/images/home/decor_next.png' alt='decor' />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.pageB}>
        <div className={styles.wrap}>
          <div className={styles.image}>
            <img src='/images/home/risk.png' alt='risk' />
          </div>
          <div className={styles.content}>
            <div className={styles.title}>无需安装部署、无需配置</div>
            <div className={styles.desc}>
              采用新一代无代理安全架构，非侵入式的检测整体云环境，
              相比基于代理（客户端）方案：
            </div>
            <div>
              <ul>
                <li>安全建设周期从平均的12-18个月压缩到几分钟之内。</li>
                <li>实时监控动态工作负载的创建及销毁，避免安全防护的盲区。</li>
                <li>完全不影响正常的业务。</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className={cName(styles.pageB, styles.pageC)}>
        <div className={styles.wrap}>
          <div className={styles.content}>
            <div className={styles.title}>看见并保护所有的云计算资产</div>
            <div className={styles.desc}>
              能检测用户的所有云资产，包含虚拟机、容器、Serverless、IaC等，
              并且能够随着用户的业务扩展，自动包含新引入的云资产。
            </div>
            <div>
              <ul>
                <li>
                  传统的基于代理（安装客户端）的解决方案，无法及时、完整的覆盖所有的云资产。
                  原因是由于兼容性问题不可能在每项资产上安装代理，或者由于用户的安装部署的速度无法支持
                  云资产的快速创建和销毁。
                </li>
                <li>
                  采用无代理是必然的选择，无代理指的是利用云平台的API或其他机制，无需在被检测资产上
                  安装客户端软件，即可对其进行安全检测。无代理会自动发现新创建的云资产，并把它们加
                  入被检测资产的范围。除此之外，无代理也可以监控暂停和已停止的工作负载、孤立系统以
                  及不支持代理的设备，避免安全检测的盲区。
                </li>
              </ul>
            </div>
          </div>
          <div className={styles.image}>
            <img src='/images/home/assets.png' alt='assets' />
          </div>
        </div>
      </div>
      <div className={styles.pageB}>
        <div className={styles.wrap}>
          <div className={styles.image}>
            <img src='/images/home/panorama.png' alt='panorama' />
          </div>
          <div className={styles.content}>
            <div className={styles.title}>“全景”情境化智能分析</div>
            <div className={styles.text}>
              平台提供威胁风险的情景化智能分析。安全团队能够通过其了解哪些攻击路径对业务最关键，
              以便他们可以首先修复这些路径。
            </div>
            <div className={styles.text}>
              基于图的可视化分析，展现潜在的攻击路径，包含可能被横向移动利用的工作负载，
              以及这些工作负载的漏洞状态、配置错误风险、信任和授权方面的风险。
            </div>
          </div>
        </div>
      </div>
      <div className={styles.pageFooter}>
        <div className={styles.wrap}>
          <div className={styles.content}>
            <div className={styles.title}>基于AI的风险预测</div>
            <div className={styles.text}>
              对常用的攻击手段、漏洞利用行为建立数据模型，并据此对用户环境进行风险预测、
              识别高优先度的风险、提供修补方案。
            </div>
            <div className={styles.image}>
              <img src='/images/home/process.png' alt='process' />
            </div>
          </div>
          <div className={styles.horizontalDivider}></div>
          <div className={styles.copyright}>
            <div className={styles.logoImg}>
              <img src='/images/home/logo_blue.png' alt='logo' />
            </div>
            <div className={styles.text}>
              <div>©2023 哨云科技（南京）有限公司版权所有.</div>
              <div>
                <a
                  className={styles.ICPNumber}
                  href='http://beian.miit.gov.cn/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  苏ICP备2023017921号-1
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popup ref={popupRef}>
        <div className={styles.modal}>
          <img
            className={styles.modalTitle}
            src='/images/reservation/tips.png'
            alt='tips'
          />
          <div className={styles.modalContent}>
            <Form
              form={form}
              name='normal_login'
              initialValues={{ remember: true }}
              onFinish={onFinish}
            >
              <Form.Item
                name='username'
                rules={[{ required: true, message: '请输入您的姓名!' }]}
              >
                <Input
                  prefix={<span className={styles.prefixCustom}>姓名</span>}
                  size='large'
                  placeholder='请输入您的姓名'
                />
              </Form.Item>
              <Form.Item
                name='phone'
                rules={[{ required: true, message: '请输入您的电话!' }]}
              >
                <Input
                  prefix={<span className={styles.prefixCustom}>电话</span>}
                  size='large'
                  placeholder='请输入您的电话'
                />
              </Form.Item>
              <Form.Item name='email'>
                <Input
                  prefix={<span className={styles.prefixCustom}>邮箱</span>}
                  size='large'
                  placeholder='请输入您的邮箱地址'
                />
              </Form.Item>
              <Form.Item name='companyName'>
                <Input
                  prefix={<span className={styles.prefixCustom}>公司</span>}
                  size='large'
                  placeholder='请输入您的公司名称'
                />
              </Form.Item>
              <Form.Item name='positionName'>
                <Input
                  prefix={<span className={styles.prefixCustom}>职位</span>}
                  size='large'
                  placeholder='请输入您在公司的职位'
                />
              </Form.Item>
              <Form.Item>
                {/* <Button
                  type='primary'
                  htmlType='submit'
                  className='login-form-button'
                >
                  <img src='/images/reservation/submit.png' alt='submit' />
                </Button> */}
                <div className={styles.buttonForm} onClick={onOk}>
                  <img src='/images/reservation/submit.png' alt='submit' />
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default Index;
